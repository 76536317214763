<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col>
          <h5 class="text-h5 text-typo font-weight-bold mb-0">Tap List</h5>
          <div class="d-flex">
            <p class="text-sm text-body mb-0">
              Some of our favorites, some of your favorites and tons of new
              stuff! Don't forget we have over 100 bottles &amp; cans for on
              premise consumption or mix and match take-out as well.
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col xs="12" xl="6" cols="12" v-for="beer of tapList" :key="beer.id">
          <v-card class="card-shadow pa-4 border-radius-xl z-index-2">
            <div class="d-flex align-center">
              <v-card
                color="#fdb826"
                dark
                height="80"
                width="80"
                class="
                  d-flex
                  flex-shrink-0
                  justify-center
                  align-center
                  card-shadow
                  mr-4
                  border-radius-xl
                  z-index-2
                "
              >
                <h1>{{ beer.tap_number }}</h1>
              </v-card>
              <div>
                <div class="d-sm-flex pb-0">
                  <h6 class="text-h6 text-typo font-weight-bold">
                    {{ beer.name }}
                  </h6>
                  <div class="pl-sm-4 font-italic">{{ beer.style }}</div>
                </div>
                <div class="d-sm-flex">
                  <h6 class="text-h6 text-typo font-weight-bold">
                    {{ beer.brewery }}
                  </h6>
                  <div class="pl-sm-4 font-italic">
                    {{ beer.brewery_location }}
                  </div>
                </div>
                <div class="d-flex">
                  <div>{{ beer.abv }}% ABV</div>
                  <div class="pl-4">Rating: {{ twoDec(beer.rating) }} / 5</div>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "TapList",
  data() {
    return {
      tapList: [],
    };
  },
  mounted() {
    this.$emit("drawer-toggle", true);
    axios
      .get(`https://business.untappd.com/api/v1/menus/74035?full=true`, {
        headers: {
          Authorization:
            "Basic YmVlckB0aGVwYWxsZXQuYmFyOnFlVmJlWGl1ZVFMYW9fa2R0V1dD",
        },
      })
      .then((res) => {
        console.log(res.data.menu);
        this.tapList = res.data.menu.sections[0].items;
        console.log(this.tapList);
      });
  },
  methods: {
    twoDec(num) {
      return parseFloat(num).toFixed(2);
    },
  },
};
</script>
